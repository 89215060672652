import {useEffect, useState} from 'react';
import './App.css';
import Typewriter from 'typewriter-effect';

const tt_link = "https://www.tiktok.com/@mutualfriendsmusic";
const ig_link = "https://www.instagram.com/mutualfriends_/?hl=en";
const shop_link = "https://mutual-friends-shop.myshopify.com/";

const quinn_link = "https://www.instagram.com/quinnxcii/?hl=en";
const ayokay_link = "https://www.instagram.com/ayokay/?hl=en";
const chelsea_link = "https://www.instagram.com/chelseacutler/?hl=en";
const jeremy_link = "https://www.instagram.com/jeremyzucker/?hl=en";

function App() {
  const [typeWriterText, setTypeWriterText] = useState('');
  
  useEffect(() => {
    
  }, [])

  return (
    <div className="App">
      <img className="mutualfriends-logo" src="https://lh3.googleusercontent.com/yWfnaJTXGbtPWD1UvaopHj0phGVZiRaz_54a3THb1twkVgCvByqnQ0Xh7QL11RamoUpgU3vpbsRPIsROStpRy8v42QSS_5L-m42Bv1lMCLFRcGzETHOEtOviRTBETOQWw7YCtg39Uw=w600-h315-p-k" />
      <div className="artists-wrapper">
        <div className="artists-links-wrapper">
          <a className="artists-link" href={ayokay_link}>AYOKAY</a>
          <span style={{color: '#b60a13', margin: "10px"}}>&#8226;</span>
          <a className="artists-link" href={chelsea_link}>CHELSEA CUTLER</a>
          <span style={{color: '#1d6dd2', margin: "10px"}}>&#8226;</span>
          <a className="artists-link" href={jeremy_link}>JEREMY ZUCKER</a>
          <span style={{color: '#e2a23a', margin: "10px"}}>&#8226;</span>
          <a className="artists-link" href={quinn_link}>QUINN XCII</a>
          </div>
      </div>
      <div className="mobile-artists-wrapper">
        <div className="mobile-artists-links-wrapper">
          <a className="mobile-artists-link" href={ayokay_link}>AYOKAY</a>
          <span style={{color: '#b60a13', margin: "10px"}}>&#8226;</span>
          <a className="mobile-artists-link" href={chelsea_link}>CHELSEA CUTLER</a>
          <span style={{color: '#1d6dd2', margin: "10px"}}>&#8226;</span>
          <a className="mobile-artists-link" href={jeremy_link}>JEREMY ZUCKER</a>
          <span style={{color: '#e2a23a', margin: "10px"}}>&#8226;</span>
          <a className="mobile-artists-link" href={quinn_link}>QUINN XCII</a> 
        </div>
      </div>
      <div className="social-links-wrapper">
        <a href={ig_link} className="social-icon-wrapper">
          <img className="social-icon" src="https://lh3.googleusercontent.com/8E9r4vV517xTkg2hbN871WeWxWOSZjA0Gw2Rqs1xKmOnUaOFJIdZE0ZmXqei-k8ZV-4ja-E8iBX9qJIuYGAXVMakTS4vYFrl8Br-gUL2la7FV5sWsb9zRL7Rg99l4AmLcPXaEbEI3g" />
        </a>
        <a href={tt_link} style={{marginLeft: '3vw', marginRight: '3vw'}} className="social-icon-wrapper">
          <img className="social-icon" src="https://lh3.googleusercontent.com/EEZsPtpHfZ8nSU0q-2-YPr1Pvojrcpg3j0CXHJoTu4MpoteUnLaY7_7EI5uVeP2DkAgPLBDIFHRtuUCPNcpzsoLh1THujHynD--Idkk-PCaec_VpPzLrHgQ5ex-Zv73gdpmgihVulw" />
        </a>
        <a href="mailto:mutualfriendscontact@gmail.com" className="social-icon-wrapper">
          <img className="social-icon" src="https://lh3.googleusercontent.com/YcP67pwc_OMQQes-CdBMg-Fdr92MRm1cLAJYM52y3XclCmCBkvkUKwjCQC8w1lKpJCvW6HWoOV8F64pF_QTv1bObPAnh8XnaqNUB5BOwQSt4qK0mxh10p9JoV-gps3RvknhVYLpKkQ" />
        </a>
        <a href={shop_link} style={{marginLeft: '3vw', marginRight: '3vw'}} className="social-icon-wrapper">
          <img className="social-icon" src="https://lh3.googleusercontent.com/ABnq7qoty6H87j8oERLU7SEhMGu0wfi3iSseVTF6SmIOhgDb-GFkFbM5r2tOE0zKOz6IRgOThkTyvNwnH7eYvWtzARAV6HcozrcsfqcH9-_GLmw1WCOX2zSrBt8mJqC1lzNgbbJwjA" />
        </a>
      </div>
      <div className="coming-soon-wrapper">
        <p style={{margin: '0'}}>COMING SOON</p>
        <Typewriter
        style={{height: 'fit-content', margin: 'auto'}}
          options={{
            strings: ['...'],
            autoStart: true,
            loop: true,
          }}
        />
      </div>
    </div>
  );
}

export default App;
